.hot-area-hoc {
    width: 100%;
    flex-shrink: 0;
    height: 162px;
    border-radius: 1.6px;
    border: 1px solid #ebedf0;
    background: #fafafa;
    font-size: 14px;
    color: #000;
    user-select: none;

    .button {
        // width: 73px;
        padding: 0px 8px;
        height: 26px;
        cursor: pointer;
        font-size: 12px;
        color: #fff;
        background-color: #1890FF;
        margin-top: 6px;

        &:hover {
            opacity: 0.6;
        }
    }
}