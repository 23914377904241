.slider-custom-wrap {
    .title {
        color: #999;
        font-size: 12px;
        margin-bottom: 4px;
    }
    .slider-wrap {
        width: 100%;
        .slider-box {
            flex: 1;
            margin-right: 12px;
            padding: 6px 8px;
            background-color: #f0f0f0;
            border-radius: 4px;
            box-sizing: border-box;
        }
    }
}