.select-wrap {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    
    .normal {
        flex: 1;
        background-color: #f0f0f0;
        color: #c2c2c2;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        user-select: none;

        &:hover {
            opacity: 0.8;
        }
    }

    & .active {
        background-color: #477AFF;
        color: #fff;
    }
}