.banner-comp-wrap {
    width: 200px;
    height: 100px;
    position: absolute;

    .media {
        position: relative;
        width: 100%;
        height: 100%;

        video {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.dot-comp-wrap {
    position: absolute;
}