.hot-banner-wrap {
    width: 200px;
    height: 100px;
    position: absolute;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}