
.input-with-label {
    display: flex;

    .before-label {
        min-width: 100px;
        padding: 0 10px;
        border-radius: 4px 0px 0px 4px;
        background: #D7D7D7;
        flex-shrink: 0;
    }

    .input {
        background-color: #f0f0f0;
        outline: none;
        border: 0;
        flex: 1;
        border-radius: 0 4px 4px 0;
        padding-left: 21px;
        // min-width: 218px;
    }
}