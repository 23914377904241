.video-comp-wrap {
    position: absolute;

    .video-content {
        position: absolute;
        background-color: #fff;
        width: 100%;
        height: 100%;
        object-fit: fill;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .mask-content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}