.config-title-wrap {
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: start;

    div {
        flex: 1;
        width: 100%;
        text-align: start;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .title {
        color: #000;
        font-weight: 700;
    }

    .detail {
        color: #CACACA;
        font-size: 13px;
        font-weight: 400;
    }
}