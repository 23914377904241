.color-picker-wrap {
    border: 1px solid #f0f0f0;
    background: #fff;
    border-radius: 5px;

    .title {
        color: #999;
        font-size: 12px;
    }

    .picker {
        border: 0.35px solid #d9d9d9;
        border-radius: 3px;
    }

    .input {
        outline: none;
        border: 0px;
        padding: 0px;
        padding-block: 0;
        block-size: 0;
        background-color: transparent;
        border-radius: 2px;
        overflow: hidden;
        cursor: pointer;

        &::-webkit-color-swatch-wrapper {
            padding: 0;
            border: none;
        }

        &::-webkit-color-swatch {
            border: none;
        }
    }
}