.comp-wrap {
    position: absolute
}

.alert-comp-wrap {
    img {
        width: 100%;
        height: 100%;
    }

    video {
        width: 100%;
        height: 100%;
    }
}

.close-btn {
    img {
        width: 100%;
        height: 100%;
    }
}