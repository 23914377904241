.background-wrap {
  background-color: transparent;
  position: absolute;

  img {
    width: 100%;
    height: 100%;
  }
}

.sub-comp-1 {
  width: 100px;
  height: 100px;
  background-color: yellow;
  position: absolute;
}
