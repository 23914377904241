.input-with-img-wrap {
    position: relative;

    img {
        position: absolute;
        right: 0;
        width: 220px;
        height: 220px;
        object-fit: contain;
        background-color: rgba(0, 0, 0, 0.53);
        border-radius: 4px;
        z-index: 9;
    }

    video {
        position: absolute;
        right: 0;
        width: 220px;
        height: 220px;
        background-color: rgba(0, 0, 0, 0.53);
        border-radius: 4px;
        z-index: 9;
    }
}