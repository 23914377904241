.nav-comp-wrap {
    position: absolute;
}

::-webkit-scrollbar {
    display: none;
}

.scrollable {
    overflow-y: scroll;
}