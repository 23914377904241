.nav-button {
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    line-height: 30px;

    &.normal {
        background-color: #efefef;
        color: #797979;

        &:hover {
            opacity: 0.6;
        }
    }

    &.blue {
        background: linear-gradient(180deg, #3D73FF 0%, #5282FF 100%);
    }

    &.blue:hover {
        background: linear-gradient(180deg, #3D73FF 0%, #6292FF 100%);
    }

    &.login {
        background: #87DB60;
    }

    &.login:hover {
        background: #97EB70;
    }
}