.input-number-bg {
    width: 155px;
    height: 30px;
    background-color: #f0f0f0;
    border-radius: 4px;
    overflow: hidden;
    input {
        flex: 1;
        outline: none;
        border: 0;
        background-color: transparent;
        padding-left: 7px;
        width: 30px;
        font-size: 13px;
        font-weight: 400;
        color: #696969;
    }

    label {
        flex-shrink: 0;
        margin-right: 8px;
        color: #999;
        user-select: none;
    }

    .arrow {
        flex-shrink: 0;
        width: 11px;
        height: 30px;
        background-color: #EBEBEB;

        div {
            width: 100%;
            height: 50%;
            cursor: pointer;
            user-select: none;
        }

        div:hover {
            opacity: 0.3;
        }

        div:nth-of-type(1) {
            transform: rotate(180deg);
            border-top: 1px solid #d0d0d0;
            box-sizing: border-box;
        }
    }
}